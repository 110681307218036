import React, { memo, useEffect, useState } from 'react'
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Modal } from 'antd';
import { json, useNavigate } from 'react-router-dom';
import { login, sendCaptcha, forgetPwd } from '../../../../api/login';
import { useDispatch, useSelector } from 'react-redux';
import { useTimer } from '../../../../hooks/hooks-times';
import './login.css'
import { user } from '../../../../store/user/actionCreators';
import { getUserInfo } from '../../../../api/profile';
import { userInfo } from '../../../../store/login/actionCreators';

const codelogin = memo((props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate()
  const [loadings, setLoadings] = useState(false);
  const [loadingsa, setLoadingsa] = useState(false);
  const [account, setValues] = useState('')
  const [vala, setVala] = useState('')
  const [valb, setValb] = useState('')
  const [valc, setValc] = useState('')
  const dispatch = useDispatch()

  const [messageApi, contextHolder] = message.useMessage();
  const [seconds, isActive, startTimer] = useTimer();
  const [seconds2, isActive2, startTimer2] = useTimer();

  //登录
  const onFinish = (values) => {
    console.log(values);
    login(values).then(res => {
      if (!res.data.success) {
        messageApi.error(res.data.errorMessage);
        return res.data;
      } else {
        localStorage.setItem('userInfo', JSON.stringify(res.data.result));
        localStorage.setItem("token", res.data.result.token);
        messageApi.info('登录成功');
        navigate('/home')
        dispatch(user(res.data.result));
        return res.data;
      }

    }).then(ress => {
      if (ress.success) {
        getUserInfo(parseInt(ress.result.userId)).then(res => {
          if (res.data.success) {
            dispatch(userInfo(res.data.result));
          }
        });
      }

    })
  };
  //忘记密码
  const onFinisha = (values) => {
    forgetPwd(values).then(res => {
      messageApi.info('修改成功');
      setIsModalOpen(false)
    }).catch(err => {
      messageApi.error(err)
    })
  };
  const onPhone = () => {
    navigate('/login/pwd')
  }
  //获取验证码
  const getCoder = () => {
    if (account == undefined || account == null || account == '') {
      messageApi.error('请输入手机号/邮箱号码');
      return;
    }

    startTimer(60);
    sendCaptcha(account).then((res) => {
      if (!res.data.success) {
        messageApi.error(res.data.errorMessage)
      } else {
        messageApi.info('验证码已发送');
      }
      setLoadings(false)
    }).catch(err => {
      setLoadings(false)
    })
  }
  //忘记密码获取验证码
  const getCodera = () => {
    if (vala == undefined || vala == null || vala == '') {
      messageApi.error('请输入手机号/邮箱号码');
      return;
    }
    startTimer2(60);
    sendCaptcha(vala).then((res) => {
      if (!res.data.success) {
        messageApi.error(res.data.errorMessage)
      } else {
        messageApi.info('验证码已发送');
      }
      setLoadingsa(false)
    }).catch(err => {
      setLoadingsa(false)
    })
  }
  const handleChange = (v) => {
    setValues(v.target.value)
  }
  const handleChangea = (v) => {
    setVala(v.target.value)
  }
  const handleChangeb = (v) => {
    setValb(v.target.value)
  }
  const handleChangec = (v) => {
    setValc(v.target.value)
  }
  const handleOk = () => {
    let obj = {
      account: vala,
      captcha: valb,
      password: valc
    }
    console.log(obj)
    setIsModalOpen(false);
  };
  const handleCancel = () => {

    setIsModalOpen(false);
  };
  const showModal = () => {
    setIsModalOpen(true);
  }
  return (
    <div>
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{
          remember: true,
        }}

        size='large'
        onFinish={onFinish}
      >
        {contextHolder}
        <Form.Item
          name="account"
          rules={[
            {
              required: true,
              message: '请输入手机号/电子邮箱',
            },
          ]}
        >
          <Input value={account} onChange={(value) => handleChange(value)} prefix={<UserOutlined className="site-form-item-icon" />} placeholder="请输入手机号/电子邮箱号" />
        </Form.Item>
        <Form.Item
          name="certificate"
          rules={[
            {
              required: true,
              message: `请输入验证码`,
            },
          ]}
        >
          <div style={{ display: 'flex', gap: '5px' }}>
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="code"
              placeholder={`请输入验证码`}
            />
            <Button disabled={isActive} loading={loadings} onClick={() => getCoder()} type='primary'>
              {isActive ? `${seconds}秒后重新获取` : '获取验证码'}
            </Button>
          </div>
        </Form.Item>
        <div style={{ display: 'flex', gap: '10px', marginBottom: '10px' }}>
          <span onClick={() => onPhone()} style={{ cursor: 'pointer', color: '#1677ff' }}>密码登录</span>
          <span style={{ cursor: 'pointer', color: 'blue' }} onClick={showModal}>忘记密码</span>
        </div>
        <Form.Item>
          <Button block type="primary" htmlType="submit" className="login-form-button">
            登 录
          </Button>
        </Form.Item>
      </Form>
      <Modal title="忘记密码" cancelText='取消' okText='关闭' open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={[]}>
        <Form
          name="forgetName"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          size='large'
          onFinish={onFinisha}
        >
          {contextHolder}
          <Form.Item
            name="account"
            rules={[
              {
                required: true,
                message: '请输入手机号/电子邮箱',
              },
            ]}
          >
            <Input value={vala} onChange={(value) => handleChangea(value)} prefix={<UserOutlined className="site-form-item-icon" />} placeholder="请输入手机号/电子邮箱" />
          </Form.Item>
          <Form.Item
            name="captcha"
            rules={[
              {
                required: true,
                message: `请输入验证码`,
              },
            ]}
          >
            <div style={{ display: 'flex', gap: '5px' }}>
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="code"
                value={valb}
                onChange={(value) => handleChangeb(value)}
                placeholder={`请输入验证码`}
              />
              <Button disabled={isActive2} loading={loadingsa} onClick={() => getCodera()} type='primary'>
                {isActive2 ? `${seconds2}秒后重新获取` : '获取验证码'}
              </Button>
            </div>
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: '请输入新密码',
              },
            ]}
          >
            <Input type='password' value={valc} onChange={(value) => handleChangec(value)} prefix={<UserOutlined className="site-form-item-icon" />} placeholder="请输入新密码" />
          </Form.Item>
          <Form.Item>
            <Button block type="primary" htmlType="submit" className="login-form-button">
              提交
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>

  );
})

export default codelogin

