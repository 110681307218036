import React, { memo, useState, useEffect } from 'react'
import { Button, message, List, Space, Avatar, Input } from 'antd';
import remarkGfm from 'remark-gfm'
import Markdown from 'react-markdown'
import copy from 'copy-to-clipboard';
import { HeartTwoTone } from '@ant-design/icons';
import './details.css'
import { useSearchParams } from 'react-router-dom';
import { shareWay, getDetailById, getDz, cancelDz, getPlList, PlDetail, DelPlDetail } from '../../api/detail';



const details = memo(() => {
  const [detailObj, SetDetailObj] = useState({})
  let [searchParams, setSearchParams] = useSearchParams()
  const [dataarr, setDataarr] = useState([])
  const [areaVal, setAreaVal] = useState('');

  // 1. 分享
  const shareLink = function () {
    let url = window.location.href;
    shareWay({ Url: url, BlogId: searchParams.get('id') }).then(res => {
      if (res.data.success == true) {
        console.log(res)
        copy(res.data.result)
        message.success('复制成功');
      } else {
        message.error('操作失败');
      }
    }).catch(err => {
      message.error('操作失败');
      console.log(err);
    })
  }

  // 获取文章详情
  const getDetail = function (id) {
    getDetailById(id).then(res => {
      if (res.data.success == true) {
        setTimeout(() => {
          SetDetailObj(res.data.result)
        }, 200);
      }
      else {
        window.location.href = '/404';
      }
    })
  }

  // 获取评论列表
  const getDetailPl = function (id) {
    var userInfo = localStorage.getItem("userInfo");
    const user = JSON.parse(userInfo);
    console.log(user);
    getPlList(id).then(res => {
      if (res.data.success) {
        let arrs = res.data.result;
        arrs.map((item, index) => {
          if (user != null)
            item.isShowDelete = user.userId == item.createId ? true : false;
          else
            item.isShowDelete = false;
        })
        setDataarr(arrs);
      }
    })
  }

  // 点赞
  const dzdetail = function (n) {
    console.log(n)
    console.log(detailObj.id)
    if (n == 1) {
      getDz(detailObj.id).then(res => {
        if (res.data.success == true) {
          message.success("点赞成功");
          let ids = searchParams.get('id');
          getDetail(ids);
          return;
        }
        throw new Error(res.data.errorMessage);
      }).catch(err => {
        message.error("操作失败");
        console.error(err);
      })
    } else if (n == 0) {
      cancelDz(detailObj.id).then(res => {
        if (res.data.success == true) {
          message.success("取消点赞成功");
          let ids = searchParams.get('id');
          getDetail(ids);
          getDetailPl(ids);
          return;
        }
        throw new Error(res.data.errorMessage);
      }).catch(err => {
        message.error("操作失败");
        console.error(err);
      })
    }
  }

  // 添加评论
  const savePl = function () {
    console.log(detailObj.id)
    console.log(areaVal)
    if (areaVal.length !== 0) {
      PlDetail({ BlogId: detailObj.id, Content: areaVal }).then(res => {
        if (res.data.success == true) {
          message.success('添加成功')
          let ids = searchParams.get('id')
          getDetail(ids)
          getDetailPl(ids)
          setAreaVal('')
        } else {
          message.error(res.data.errorMessage)
        }
      })
    } else {
      message.error('请填写评论内容')
    }
  }

  // 删除评论
  const delPlitem = function (item) {
    DelPlDetail(item.id).then(res => {
      if (res.data.success == true) {
        message.success('删除成功')
        let ids = searchParams.get('id')
        getDetail(ids)
        getDetailPl(ids)
      } else {
        message.error(res.data.errorMessage)
      }
    })
  }
  useEffect(() => {
    let ids = searchParams.get('id')
    getDetail(ids)
    getDetailPl(ids)
  }, [])
  return (
    <div className='contentbox'>
      <div style={{ width: '80%', margin: '0 auto' }}>
        <div className='btnbox'>
          <HeartTwoTone onClick={() => dzdetail(0)} className={detailObj.isLike == true ? '' : 'hides'} twoToneColor="#eb2f96" style={{ float: 'right', transform: 'scale(2)', marginLeft: '2%', marginTop: '5px' }} />
          <HeartTwoTone onClick={() => dzdetail(1)} className={detailObj.isLike == true ? 'hides' : ''} style={{ float: 'right', transform: 'scale(2)', marginLeft: '2%', marginTop: '5px' }} />
          <Button style={{ float: 'right' }} type="primary" onClick={shareLink}>分享</Button>
        </div>
        <div className='hei10'></div>
        <div className='hei10'></div>

        <Markdown remarkPlugins={[remarkGfm]}>{detailObj.content}</Markdown>
        <div className='hei10'></div>
        <div className='plInput'>
          <Space.Compact style={{ width: '100%', height: 40 }}>
            <Input placeholder="请输入评论内容" value={areaVal} onChange={(e) => { setAreaVal(e.currentTarget.value); }} />
            <Button type="primary" onClick={savePl} style={{ height: "100%" }}>发布评论</Button>
          </Space.Compact>
        </div>
        <div className='hei10'></div>
        <div className='pllist'>
          <List
            itemLayout="vertical"
            size="large"
            pagination={false}
            dataSource={dataarr}
            renderItem={(item) => (
              <List.Item
                key={item.id}
                actions={[
                  <span>{item.createTime}</span>
                ]}
                extra={
                  <Button type="primary" disabled={!item.isShowDelete} danger ghost onClick={(e) => delPlitem(item)}>删除</Button>
                }
              >
                <List.Item.Meta
                  avatar={<Avatar src={item.avatar} />}
                  title={<a href='#'>{item.createName}</a>}
                />
                {item.content}
              </List.Item>
            )}
          />
        </div>
      </div>
    </div>

  )
})
export default details

