import { CHANNEL } from "./constans"

//初始化数据
const initialState = {
  channel: { },
}
//定义reducer函数
function reducer(state = initialState, action) {
  switch (action.type) {
    case CHANNEL:
      return {...state, channel: action.name}
    default:
      return state
  }
}

export default reducer