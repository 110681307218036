import request from './request.js'
// 请求草稿箱等
export function getdraf(params) {
    return request({
        url: '/api/blog/list',
        method: 'GET',
        params: params,
        headers: {
            'api-type': 1
        }
    })
}

export function getAdminList(params) {
    return request({
        url: '/api/blog/admin/list',
        method: 'GET',
        params: params,
        headers: {
            'api-type': 1
        }
    })
}
