import forge from "node-forge";

export function encrypt(str, key) {
  // const forge = new forges()
  const RSA_KEY = key
  // 加密逻辑
  const publicKey = forge.pki.publicKeyFromPem(RSA_KEY);
  let buffer = forge.util.createBuffer(str, 'utf8');
  let bytes = buffer.getBytes();
  let encryptedText = forge.util.encode64(publicKey.encrypt(bytes, 'RSAES-PKCS1-V1_5', {
    md: forge.md.sha256.create(),
    mgf1: {
      md: forge.md.sha1.create()
    }
  }));
  return encryptedText
}
