import { useRoutes } from "react-router-dom";
import routes from "./routes/routes";
import './assets/app.css';

function App() {
  // const dispatch = useDispatch()
  // useEffect(() => {
  //   getChannel().then((res) => {
  //     console.log(res.data)
  //     dispatch(channelInfo(res.data))
  //   }).catch((err) => {
      
  //   })
  // })
  return (
    <div className="App">
      {useRoutes(routes)}
    </div>
  );
}

export default App;
