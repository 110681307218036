import React, { memo, useState, useEffect } from 'react'
import { Space, Table, Tag, Button, message } from 'antd';
import { getdraf } from '../../../api/home';
import { unpublishBlog } from '../../../api/markdown';
import Item from 'antd/es/list/Item';
import { useLocation } from "react-router-dom";
const check = memo(() => {
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  const [dataList, setDatalist] = useState([]);//列表数据
  let location = useLocation();

  // 请求发布箱
  const getdrafs = () => {
    let info = JSON.parse(localStorage.params)
    let parmas = {
      category: 0,
      page: current,
      pagesize: pageSize,
      states: 2
    }

    getdraf(parmas).then(res => {
      console.log(res);
      let data = res.data.result.data.map((item, i) => {
        return {
          key: i,
          name: item.author,
          content: item.content,
          title: item.title,
          id: item.id,
          mainImage: item.mainImage
        }
      });
      setDatalist(data)
    })
  }

  // 撤销发布博客
  const unpublishList = (index) => {
    let id = dataList[index].id
    unpublishBlog(id).then(res => {
      if (res.data.success) {
        message.success('撤销发布成功')
        getdrafs();
      } else {
        message.warning(res.data.errorMessage)
      }
    })
  }

  useEffect(() => {
    getdrafs()
  }, [])

  const columns = [
    {
      title: '作者',
      dataIndex: 'name',
      align: 'center',
      key: 'name',
      render: (text) => <p>{text}</p>,
    },
    {
      title: '标题',
      key: 'title',
      dataIndex: 'title',
      align: 'center',
    },
    {
      title: '简要内容',
      dataIndex: 'content',
      key: 'content',
      align: 'center',
    },
    {
      title: '操作',
      key: 'action',
      dataIndex: 'action',
      align: 'center',
      render: (text, record, index) => (
        <>
          <Button type="default" danger onClick={() => unpublishList(index)}>撤销发布</Button>
        </>
      )
    },
  ];

  return (
    <div style={{ background: '#fff', borderRadius: '10px', padding: '10px', height: '100%' }}>
      <Table
        bordered
        columns={columns}
        dataSource={dataList}
        pagination={{
          current,
          pageSize,
          total: dataList.length,
          showTotal(totla, range) {
            return `共 ${totla} 条`
          },
          onChange(page, pageSize) {
            setCurrent(page)
            setPageSize(pageSize)
          }
        }} />
    </div>
  )
})

export default check
