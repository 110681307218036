import React, { memo, useState } from 'react'
import { Card, Button, Modal, Divider, Form, message } from 'antd'
import '../css/account.css'
import { useSelector } from 'react-redux'
import { Input } from 'antd';
import { sendCaptcha } from '../../../api/login';
import { updatePwdInfo, updatePhoneInfo, updateEmailInfo } from '../../../api/account';
import { logOut, forgetPwd } from "../../../api/login"
import { useTimer } from '../../../hooks/hooks-times';
const account = memo(() => {
  //const [value, setValue] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [pwd, setPwd] = useState(0)
  const [messageApi, contextHolder] = message.useMessage();
  const [loadingsa, setLoadingsa] = useState(false);
  const [vala, setVala] = useState('');
  const formRef = React.createRef();
  const formRef2 = React.createRef();
  const [seconds, isActive, startTimer] = useTimer();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [valaccount, setValaccount] = useState('');
  const [valcode, setValCode] = useState('');
  const [valpwd, setValpwd] = useState('');
  const [seconds2, isActive2, startTimer2] = useTimer();

  const showModal = (p) => {
    if (formRef.current !== null) {
      formRef.current.resetFields();
      setVala('');
    }
    if (p == 0) {
      setIsModalOpen(true);
      setPwd(0)
    } else if (p == 1) {
      setIsModalOpen(true);
      setPwd(1);
    } else if (p == 2) {
      setIsModalOpen(true);
      setPwd(2);
    } else if (p == 3) {
      setIsModalOpen(true);
      setPwd(3);
    }

  };
  const handleOk = () => {
    formRef.current.onFinish()
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setPwd(0);
    setVala('');
    setIsModalOpen(false);
    formRef.current.resetFields();
  };

  const handleOk2 = () => {
    formRef2.current.onFinish()
    setIsModalOpen2(false);
  };
  const handleCancel2 = () => {
    setIsModalOpen2(false);
    formRef2.current.resetFields();
  };
  const onFinish = (values) => {
    const updateInfo = { ...values }
    if (pwd == 0) {
      updatePwdInfo(updateInfo).then(function (response) {
        if (response.data.success == true) {
          messageApi.success('提交成功')
          setIsModalOpen(false);
          localStorage.clear();
          window.location.href = '/login';
        } else {
          messageApi.error(response.data.errorMessage)
        }
      }).catch(function (error) {
        console.log(error);
      });
    } else if (pwd == 1) {
      if (userInfo.phone == '') {
        updateInfo.phone = '';
      }
      updatePhoneInfo(updateInfo).then(function (response) {
        if (response.data.success == true) {
          messageApi.success('提交成功')
          setIsModalOpen(false);
          logOut().then(res => {
            if (res.data.success) {
              localStorage.clear();
              window.location.href = '/login';
            }
          })
        } else {
          messageApi.error(response.data.errorMessage)
        }
      }).catch(function (error) {
        console.log(error);
      });

    } else if (pwd == 2) {
      if (userInfo.email == '') {
        updateInfo.email = '';
      }
      updateEmailInfo(updateInfo).then(function (response) {
        if (response.data.success == true) {
          messageApi.success('提交成功')
          setIsModalOpen(false);
          logOut().then(res => {
            if (res.data.success) {
              localStorage.clear();
              window.location.href = '/login';
            }
          })
        } else {
          messageApi.error(response.data.errorMessage)
        }
      }).catch(function (error) {
        console.log(error);
      });
    }
    // console.log('Success:', values);
    formRef.current.resetFields();
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  //获取验证码
  const getCodera = () => {

    if (vala == '' && pwd == 1) {
      messageApi.error('请输入新手机号');
      return;
    }
    if (vala == '' && pwd == 2) {
      messageApi.error('请输入新邮箱号');
      return;
    }
    console.log(pwd, vala);
    if (pwd == 1 && !/^1[3-9]\d{9}$/.test(vala)) {
      messageApi.error('请输入正确的手机号');
      return;
    }
    if (pwd == 2 && !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(vala)) {
      messageApi.error('请输入正确的邮箱号');
      return;
    }
    startTimer(60);
    sendCaptcha(vala).then((res) => {
      if (!res.data.success) {
        messageApi.error(res.data.errorMessage)
      } else {
        messageApi.info('验证码已发送');
      }
      setLoadingsa(false)
    }).catch(err => {
      setLoadingsa(false)
    })
  }
  const handleChangea = (v) => {
    setVala(v.target.value)
  }

  //获取验证码
  const getCodera2 = () => {
    if (valaccount == null || valaccount == '') {
      messageApi.error('请输入手机号/邮箱号');
      return;
    }
    startTimer2(60);
    sendCaptcha(valaccount).then((res) => {
      if (!res.data.success) {
        messageApi.error(res.data.errorMessage);
      } else {
        messageApi.info('验证码已发送');
      }
      setLoadingsa(false)
    }).catch(err => {
      setLoadingsa(false)
    })
  }

  const onFinishb = (values) => {
    forgetPwd(values).then(res => {
      if (res.data.success) {
        message.info('修改成功');
        setIsModalOpen2(false);
        formRef2.current.resetFields();
        logOut().then(res => {
          if (res.data.success) {
            localStorage.clear();
            window.location.href = '/login';
          }
        })
      }
      else {
        message.error(res.data.errorMessage);
      }
    }).catch(err => {
      message.error(err)
    })
  }

  return (
    <div>
      {contextHolder}
      <Card title='账号设置'>
        <>
          <div className='account-item'>
            <div>密 码</div>
            <div>
              {
                userInfo?.hadPassword ?
                  <Button onClick={() => showModal(0)} type='link'>修改密码</Button> :
                  <Button onClick={() => setIsModalOpen2(true)} type='link'>设置密码</Button>
              }

            </div>
          </div>
          <Divider />
          <div className='account-item'>
            <div>手机</div>
            <div>
              {userInfo?.phone == '' ? "" : userInfo?.phone}
              {
                userInfo.phone == '' ?
                  <Button onClick={() => showModal(1)} type='link'>绑定手机</Button> :
                  <Button onClick={() => showModal(1)} type='link'>修改手机</Button>
              }
            </div>
          </div>
          <Divider />
          <div className='account-item'>
            <div>邮箱</div>
            <div>
              {userInfo?.email == '' ? "" : userInfo.email}
              {
                userInfo.email == '' ?
                  <Button onClick={() => showModal(2)} type='link'>绑定邮箱</Button> :
                  <Button onClick={() => showModal(2)} type='link'>修改邮箱</Button>
              }
            </div>
          </div>
          <Divider />
        </>
      </Card>

      <Modal title={pwd == 0 ? '密码' : pwd == 1 ? "手机" : pwd == 2 ? "邮箱" : "注销账号"}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}>
        <Form
          ref={formRef}
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          {
            pwd == 1 && userInfo.phone == '' ? <></> :
              pwd == 2 && userInfo.email == '' ? <></> :
                <Form.Item
                  label={pwd == 0 ? '原密码' : pwd == 1 ? "原手机" : pwd == 2 ? "原邮箱" : ""}
                  name={pwd == 0 ? 'old_password' : pwd == 1 ? "phone" : pwd == 2 ? "email" : ""}
                  rules={[
                    {
                      required: true,
                      message: pwd == 0 ? '请输入原密码' : pwd == 1 ? "请输入原手机号" : pwd == 2 ? "请输入原邮箱号" : "",
                    },
                  ]}
                >
                  <Input placeholder={pwd == 0 ? '请输入原密码' : pwd == 1 ? "请输入原手机号" : pwd == 2 ? "请输入原邮箱号" : ""} type={pwd == 0 ? 'password' : 'text'} />
                </Form.Item>
          }
          <Form.Item
            label={pwd == 0 ? '新密码' : pwd == 1 ? "新手机" : pwd == 2 ? "新邮箱" : ""}
            name={pwd == 0 ? 'password' : pwd == 1 ? "new_phone" : pwd == 2 ? "new_email" : ""}
            rules={[
              {
                required: true,
                message: pwd == 0 ? '请输入新密码' : pwd == 1 ? "请输入新手机号" : pwd == 2 ? "请输入新邮箱号" : "",
              },
            ]}
          >
            <Input placeholder={pwd == 0 ? '请输入新密码' : pwd == 1 ? "请输入新手机号" : pwd == 2 ? "请输入新邮箱号" : ""} type={pwd == 0 ? 'password' : 'text'} value={vala} onChange={(value) => handleChangea(value)} />
          </Form.Item>

          {pwd !== 0 ?
            <Form.Item name="code"
              rules={[
                {
                  required: true,
                  message: `请输入验证码`,
                },
              ]}
              label="验证码"
            >
              <div style={{ display: 'flex', gap: '5px' }}>
                <Input type="code" placeholder={'请输入验证码'} />
                <Button disabled={isActive} loading={loadingsa} onClick={() => getCodera()} type='primary'>
                  {isActive ? `${seconds}秒后重新发送` : '获取验证码'}
                </Button>
              </div>
            </Form.Item> : ''
          }
          <Form.Item>
            <Button type="primary" htmlType="submit" block>提交</Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal title="设置密码" cancelText='取消' okText='关闭' open={isModalOpen2} onOk={handleOk2} onCancel={handleCancel2} footer={[]}>
        <Form
          name="forgetName"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          ref={formRef2}
          size='large'
          onFinish={onFinishb}
        >
          <Form.Item
            name="account"
            rules={[
              {
                required: true,
                message: '请输入手机号/邮箱号',
              },
            ]}
          >
            <Input value={valaccount} onChange={(value) => { setValaccount(value.target.value) }} placeholder="请输入手机号/邮箱号" />
          </Form.Item>
          <Form.Item
            name="captcha"
            rules={[
              {
                required: true,
                message: `请输入验证码`,
              },
            ]}
          >
            <div style={{ display: 'flex', gap: '5px' }}>
              <Input
                type="code"
                value={valcode}
                onChange={(v) => setValCode(v.target.value)}
                placeholder={`请输入验证码`}
              />
              <Button disabled={isActive2} loading={loadingsa} onClick={() => getCodera2()} type='primary'>
                {isActive2 ? `${seconds2}秒后重新发送` : '获取验证码'}
              </Button>
            </div>
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: '请输入新密码！',
              },
            ]}
          >
            <Input type="password" value={valpwd} onChange={(value) => setValpwd(value)} placeholder="请输入新密码" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              提交
            </Button>
          </Form.Item>
        </Form>
      </Modal>

    </div>
  )
})

export default account
