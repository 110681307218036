import React, { memo, useState, useEffect } from 'react'
import { Space, Table, Tag, Button, message } from 'antd';
import { getdraf } from '../../../api/home'
import { delete_, recover_ } from '../../../api/dust'
import Item from 'antd/es/list/Item';
import { useNavigate, useLocation } from "react-router-dom";

const check = memo(() => {
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  const [dataList, setDatalist] = useState([]);//列表数据
  const navigate = new useNavigate();
  let location = useLocation();
  console.log(location);

  // 请求垃圾箱
  const getdrafs = () => {
    let parmas = {
      category: 0,
      page: current,
      pagesize: pageSize,
      searchKey: '',
      states: 3
    }

    getdraf(parmas).then(res => {
      let data = res.data.result.data.map((item, i) => {
        return {
          key: i,
          name: item.author,
          content: item.content,
          title: item.title,
          id: item.id,
          mainImage: item.mainImage
        }
      });
      setDatalist(data)
    })
  }

  // 博客删除
  const deleteList = (index) => {
    let id = dataList[index].id
    let parmas = {
      id: id,
      state: 1
    }
    delete_(parmas).then(res => {
      console.log('删除成功', res);
      if (res.data.success) {
        message.success('删除成功')
        getdrafs();
      } else {
        // console.log(1);
        message.warning(res.data.errorMessage)
      }
    })
  }
  //博客恢复
  const recoverList = (index) => {
    // console.log(id);
    let id = dataList[index].id
    recover_(id).then(res => {
      console.log('恢复成功', res);
      if (res.data.success) {
        message.success('恢复成功')
        getdrafs();
      } else {
        // console.log(1);
        message.warning(res.data.errorMessage)
      }
    })
  }

  useEffect(() => {
    getdrafs()
  }, [])

  const columns = [
    {
      title: '作者',
      dataIndex: 'name',
      align: 'center',
      key: 'name',
      render: (text) => <p>{text}</p>,
    },
    {
      title: '标题',
      key: 'title',
      dataIndex: 'title',
      align: 'center',
    },
    {
      title: '简要内容',
      dataIndex: 'content',
      key: 'content',
      align: 'center',
    },

    {
      title: '操作',
      key: 'action',
      align: 'center',
      render: (_, record, index) => (
        <>
          <Button onClick={() => deleteList(index)} type="primary" danger>删除</Button>
          <Button style={{ marginLeft: '10px' }} onClick={() => recoverList(index)} type="primary">恢复</Button>
        </>
      ),
    },
  ];

  return (
    <div style={{ background: '#fff', borderRadius: '10px', padding: '10px', height: '100%' }}>
      <Table
        bordered
        columns={columns}
        dataSource={dataList}
        pagination={{
          current,
          pageSize,
          total: dataList.length,
          showTotal(totla, range) {
            return `共 ${totla} 条`
          },
          onChange(page, pageSize) {
            setCurrent(page)
            setPageSize(pageSize)
          }
        }} />
    </div>
  )
})

export default check
