import React, { memo, useState, useEffect } from 'react'
import { List, Skeleton, Divider, Layout } from 'antd';
import { LikeOutlined, MessageOutlined } from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate, useLocation } from 'react-router-dom';
import { getList } from '../../../api/searchlist';
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'


const data = Array.from({
  length: 23,
}).map((_, i) => ({
  href: 'https://ant.design',
  title: `ant design part ${i}`,
  avatar: `https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png`,
  description:
    'Ant Design, a design language for background applications, is refined by Ant UED Team.',
  content:
    'We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.',
}));



const list = memo(() => {
  const [dataarr, setDataarr] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  let paramsa = {}
  if (localStorage.getItem('params') == null) {
    paramsa = {
      category: 0,
      page: 1,
      pagesize: 50,
      searchKey: '',
      state: null
    }
    localStorage.setItem('params', JSON.stringify(paramsa))
  } else {
    paramsa = JSON.parse(localStorage.getItem('params'))
  }

  const loadMoreData = () => {
    if (loading) {
      return;
    }

    setLoading(true);
    getList(paramsa).then(res => {
      // 1. 设置是否含有其他页面
      setHasMore(res.current_page < res.total_page);
      // 2. 设置数据
      setDataarr(res.data.result.data);

      // 3. 禁止加载
      setLoading(false);
    }).catch(err => {
      setLoading(false);
    })
  };

  useEffect(() => {
    loadMoreData();
    console.log('路由变化了，当前路由是：', location.pathname);
  }, [location]);
  const navigate = useNavigate()
  const onDetail = (item) => {
    navigate('/home/article/details?id=' + item.id)
  }
  return (
    <div className='list'>
      <InfiniteScroll
        dataLength={dataarr.length}
        next={loadMoreData}
        hasMore={hasMore}
        loader={
          <Skeleton
            paragraph={{
              rows: 1,
            }}
            style={{
              textAlign: 'center'
            }}
            active
          />
        }
        endMessage={
          <div style={{ textAlign: 'center' }}>
            <Divider plain>没有更多数据了 🤐</Divider>
          </div>
        }
        scrollableTarget="scrollableDiv"
      >
        <List
          itemLayout="vertical"
          size="large"
          dataSource={dataarr}
          footer

          renderItem={(item) => (
            <>
              <List.Item
                key={item.title}
                onClick={() => onDetail(item)}
              >
                <div className='list-title'>
                  <div className='title'>{item.title}</div>
                  <div className='author'>{item.author}</div>
                </div>
                <div className='list-item'>
                  <div className='image'>
                    <img width={200} src={item.mainImage} alt='' />
                  </div>
                  <div className='list-item-right'>
                    <Markdown remarkPlugins={[remarkGfm]}>{item.content}</Markdown>
                    <div className='icon-text'>
                      <span className='item'><LikeOutlined />{item.likeNum}</span>
                      <span className='item'><MessageOutlined />{item.reviewNum}</span>
                    </div>
                  </div>
                </div>

              </List.Item>
            </>
          )}
        />
      </InfiniteScroll>
    </div>
  )
})

export default list
