import { createStore, combineReducers, compose } from "redux";
import loginReducer from './login/index'
import channelReducer from './channel/index'
import userReducer from './user/index'
import profileReducer from './profile/index'
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

//合并reducer
const reducer = combineReducers({
  userInfo:loginReducer,
  channel:channelReducer,
  user:userReducer,
  profile:profileReducer
})

const store = createStore(reducer)

export default store