import React, { useEffect, useState } from 'react';
import { PicCenterOutlined, CheckOutlined, CheckCircleOutlined, MenuUnfoldOutlined, MenuFoldOutlined, DeleteOutlined } from '@ant-design/icons';
import { Layout, Menu, theme, Button, message } from 'antd';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
const { Header, Content, Sider } = Layout;
const App = () => {
  const items = [
    {
      key: '/check',
      icon: <CheckOutlined />,
      label: `草稿箱`
    },
    {
      key: '/overcheck',
      icon: <CheckCircleOutlined />,
      label: `已发布`
    },
    {
      key: '/dustbin',
      icon: <DeleteOutlined />,
      label: `垃圾箱`
    },
    {
      key: '/disabled',
      icon: <DeleteOutlined />,
      label: `禁用列表`
    }
  ];
  const userinfo = localStorage.getItem('userInfo');
  if (userinfo == undefined)
    navigate('/login');
  else {
    let isAdmin = JSON.parse(userinfo).isAdmin;
    if (isAdmin == true) {
      items.push({
        key: '/admin',
        icon: <PicCenterOutlined />,
        label: `管理模块`
      })
    }
  }

  const navigate = useNavigate()
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const [collapsed, setCollapsed] = useState(false);
  const [status, setStatus] = useState('');//博客状态
  const [selectcheck, setSelectcheck] = useState('');

  // 获取路由参数
  let location = useLocation();
  const jump = () => {
    if (location.state.state == 1) {
      let value = {
        key: ''
      }
      value.key = '/check'
      setSelectcheck(value.key)
    } else if (location.state.state == 2) {
      let value = {
        key: ''
      }
      value.key = '/overcheck'
      setSelectcheck(value.key)
    } else if (location.state.state == 3) {
      let value = {
        key: ''
      }
      value.key = '/dustbin'
      setSelectcheck(value.key)
    }
    else if (location.state.state == 4) {
      let value = {
        key: ''
      }
      value.key = '/disabled'
      setSelectcheck(value.key)
    }
  }



  // 获取其他参数
  const info = JSON.parse(localStorage.getItem('params'));

  useEffect(() => {
    const handlePopstate = () => {
      console.log('页面回退了！');
      // 在这里可以执行你想要的回退时的逻辑
      navigate('/home/article')
    };
    window.addEventListener('popstate', handlePopstate);
    jump();
    // getstatus()

  }, [status])

  const onSilderChange = (value) => {
    console.log(value);
    switch (value.key) {
      case '/check':
        const newStatus = '1'
        setStatus(newStatus);
        setStatus(res => {
          return res
        })
        console.log(status);
        navigate('/home/homes/check', {
          state: { status: 1 }
        });
        setSelectcheck('/check')
        console.log(value.key);
        break;
      case '/overcheck':
        const anotherStatus = '2'
        setStatus(anotherStatus);
        setStatus(res => {
          return res
        })
        console.log(status);
        navigate('/home/homes/overcheck', {
          state: { status: 2 }
        });
        setSelectcheck('/overcheck')
        break;
      case '/dustbin':
        const yesStatus = '3'
        setStatus(yesStatus);
        setStatus(res => {
          return res
        })
        console.log(status);
        navigate('/home/homes/dustbin', {
          state: { status: 3 }
        });
        setSelectcheck('/dustbin')
        break;
      case '/admin':
        let userinfo = JSON.parse(localStorage.getItem('userInfo'))
        if (userinfo.isAdmin == true) {
          const adminStatus = '4'
          setStatus(adminStatus);
          setStatus(res => {
            return res
          })
          console.log(status);
          navigate('/home/homes/admin', {
            state: { status: 2 }
          });
          setSelectcheck('/admin')
        } else {
          message.error('您没有管理员权限')
        }

        break;
      case '/disabled':
        const disabledStatus = '2'
        setStatus(disabledStatus);
        setStatus(res => {
          return res
        })
        navigate('/home/homes/disabled', {
          state: { status: 4 }
        });
        setSelectcheck('/disabled')
        break;
      default:
        break;
    }

  }

  return (
    <>
      <Layout hasSider>
        <Sider
          theme='drak'
          style={{ overflow: 'auto', height: '100vh', position: 'fixed', left: 0, top: 0, bottom: 0 }}
        >
          <div className="demo-logo-vertical" />
          <Menu
            theme="dark"
            mode="inline"
            selectedKeys={[selectcheck]}
            items={items}
            onClick={(value) => onSilderChange(value)} />
        </Sider>
        <Layout.Content>
          <Content>
            <div
              style={{
                padding: 24,
                marginLeft: "90px",
                textAlign: 'center',
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
              }}
            >
              <Outlet />
            </div>

          </Content>


        </Layout.Content>
      </Layout>
    </>
  );
};
export default App;