import React, { memo } from 'react'
import { CheckOutlined, CheckCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Card, FloatButton, Avatar, Row, Col, Drawer } from 'antd';
import MList from './list/list';
import './css/article.css'
import { useNavigate } from 'react-router-dom';
import app from '../../app.config.json';

export default memo(() => {
  const navigate = useNavigate()
  const onMarkown = () => {
    navigate('/home/article/markown',
      {
        state: {
          isedit: 0
        }
      })
  }
  const goAdmin = (val) => {
    if (val == 1) {
      navigate('/home/homes/check',
        {
          state: { state: val }
        })
    } else if (val == 2) {
      navigate('/home/homes/overcheck',
        {
          state: { state: val }
        })
    } else {
      navigate('/home/homes/dustbin',
        {
          state: { state: val }
        })
    }

  }
  const isLogin = !(localStorage.getItem('userInfo') == undefined);
  return (
    <div className='article'>
      <MList />
      <div className='userInfo'>
        <Card
          style={{
            width: 300,
            marginBottom: '10px',
            display: isLogin ? 'block' : 'none',
          }}
        >
          <Row style={{
            marginBottom: "20px",
            cursor: "pointer"
          }} onClick={() => goAdmin(1)}>
            <Col span={3}>
              <CheckOutlined />
            </Col>
            <Col span={15} />
            <Col span={6}>
              <p style={{ padding: "10px" }}>草稿箱</p>
            </Col>
          </Row>
          <Drawer size='1' />
          <Row style={{
            marginBottom: "20px",
            cursor: "pointer"
          }} onClick={() => goAdmin(2)}>
            <Col span={3}>
              <CheckCircleOutlined />
            </Col>
            <Col span={15} />
            <Col span={6}>
              <p style={{ padding: "10px" }}>已发布</p>
            </Col>
          </Row>
          <Drawer />
          <Row style={{
            marginBottom: "20px",
            cursor: "pointer",

          }} onClick={() => goAdmin(3)}>
            <Col span={3}>
              <DeleteOutlined />
            </Col>
            <Col span={15} />
            <Col span={6}>
              <p style={{ padding: "10px" }}>垃圾箱</p>
            </Col>
          </Row>
          <Button onClick={() => onMarkown()} type="primary" block>+开始创作</Button>
        </Card>
        <Card
          style={{
            width: 300,
          }}

        >
          <div style={{ textAlign: 'center' }}>
            <Row justify="center" className='record'>
              <Col span={24}>
                <p>{app.copyright.replace("{year}", new Date().getFullYear())}</p>
              </Col>
              <Col>
                <a target="_blank" href="https://beian.miit.gov.cn" >{app.record.ICP}</a>
              </Col>
              <Col>
                <a target='_blank' href={"http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44070502000613"}>
                  <img src='../recordIcon.png' style={{ float: "left", color: "black", }} />
                  <p style={{
                    float: "left",
                    height: "20px",
                    lineHeight: "20px",
                    margin: "0px 0px 0px 5px",
                  }}>{app.record.PublicSecurity.number}
                  </p>
                </a>
              </Col>
            </Row>
          </div>
        </Card>
      </div>
      <FloatButton.BackTop />
    </div>
  )
})
