import React, { memo, useState, useEffect } from 'react'
import { Table, Space, Button, message } from 'antd';
import { getdraf } from '../../../api/home'
import { publishBlog } from '../../../api/markdown';
import { delete_ } from '../../../api/dust'
import { useNavigate, useLocation } from "react-router-dom";
const check = memo(() => {
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [dataList, setDatalist] = useState([]);//列表数据

  // 请求草稿箱
  const getdrafs = () => {
    let parmas = {
      category: 0,
      page: current,
      pagesize: pageSize,
      searchKey: '',
      states: 1
    }
    getdraf(parmas).then(res => {
      let data = res.data.result.data.map((item, i) => {
        return {
          key: i,
          name: item.author,
          content: item.content,
          title: item.title,
          id: item.id,
          mainImage: item.mainImage,
        }
      });
      // console.log(data);
      setDatalist(data)

    })
  }
  const navigate = useNavigate()
  // 博客编辑
  const goEdit = (index) => {
    navigate('/home/article/markown', {
      state: {
        info: dataList[index],
        isedit: 1
      }
    })
  }

  // 博客发布
  const publishEndit = (index) => {
    let id = dataList[index].id
    publishBlog(id).then(res => {
      if (res.data.success) {
        message.success('发布成功')
        getdrafs();
      } else {
        message.warning(res.data.errorMessage)
      }
    })
  }

  // 删除博客
  const deleteBlog = (index) => {
    let id = dataList[index].id;
    let params = {
      id: id,
      states: 0
    }

    delete_(params).then(res => {
      if (res.data.success) {
        message.success('删除成功')
        getdrafs();
      } else {
        message.warning(res.data.errorMessage)
      }
    }).catch(err => {
      message.error(err);
    })
  }

  useEffect(() => {
    getdrafs()
  }, [])

  const columns = [
    {
      title: '作者',
      dataIndex: 'name',
      align: 'center',
      key: 'name',
      render: (text) => <p>{text}</p>,
    },
    {
      title: '标题',
      key: 'title',
      dataIndex: 'title',
      align: 'center',
    },
    {
      title: '简要内容',
      dataIndex: 'content',
      key: 'content',
      align: 'center',
    },
    {
      title: '操作',
      key: 'action',
      align: 'center',
      render: (_, record, index) => (
        <Space>
          <Button onClick={() => publishEndit(index)} type="primary">发布</Button>
          <Button onClick={() => goEdit(index)} type="default">编辑</Button>
          <Button onClick={() => deleteBlog(index)} type="primary" danger>删除</Button>
        </Space>
      ),
    },
  ];

  return (
    <div style={{ background: '#fff', borderRadius: '10px', padding: '10px', height: '100%' }}>
      <Table
        bordered
        columns={columns}
        dataSource={dataList}
        pagination={{
          current,
          pageSize,
          total: dataList.length,
          showTotal(totla, range) {
            return `共 ${totla} 条`
          },
          onChange(page, pageSize) {
            console.log(page, pageSize)
            setCurrent(page)
            setPageSize(pageSize)
          }
        }} />
    </div>
  )
})

export default check
