import { Button, Card, Divider, Modal, Form, Input, Select, message, Upload, Image } from 'antd'
import React, { memo, useEffect, useState } from 'react'
import '../css/mprofile.css'
import { userInfo } from '../../../store/login/actionCreators';
// 获取redux
import { useDispatch } from 'react-redux'


import { getUserInfo, updateUserInfo } from '../../../api/profile'
import dayjs from 'dayjs'

const mprofile = memo(() => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [userinfo, setuserInfo] = useState({})
  const dispatch = useDispatch();

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    getUserInfo(parseInt(userinfo.userId)).then(res => {
      if (res.data.success) {
        dispatch(userInfo(res.data.result));
        localStorage.setItem("avatar", res.data.result.avatar);
        localStorage.setItem("userInfo", JSON.stringify(res.data.result));
      }
    }).then(() => {
      form.resetFields();
    });
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const onFinish = (values) => {
    const obj = { Avatar: userinfo.avatar, Nickname: values.nickname, Gender: parseInt(values.gender), Id: parseInt(userinfo.userId) };
    updateUserInfo(obj).then(res => {
      if (res.data.success) {
        console.log(res);
        messageApi.info('提交成功');
        handleOk();
        returnInfo();

      } else {
        console.log(res);
        messageApi.info(res.data.errorMessage);
        handleOk();
        returnInfo();
      }
    }).catch(function (error) {
      console.log(error);
    });
  };

  // 回显用户信息
  const returnInfo = (value) => {
    let { userId } = JSON.parse(localStorage.getItem('userInfo'));
    getUserInfo(userId).then(res => {
      console.log(res.data.result);
      dispatch(userInfo(res.data.result));
      setuserInfo(res.data.result);
      localStorage.setItem("avatar", res.data.result.avatar);
      localStorage.setItem("userInfo", JSON.stringify(res.data.result));
    })
  }
  const onChange = function (info) {
    if (info.file.response !== undefined) {
      userinfo.avatar = info.file.response.result
      let obj = userinfo
      setuserInfo({ ...obj })
      let params = {
        Id: userinfo.userId,
        Nickname: userinfo.nickname,
        Avatar: userinfo.avatar,
        Gender: userinfo.gender
      }
      updateUserInfo(params).then(res => {
        messageApi.info('更新成功');
        returnInfo()
      })
    }
  }
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const showGender = (gender) => {
    switch (gender) {
      case 0: return "保密";
      case 1: return "男";
      case 2: return "女";
    }
  }

  const [form] = Form.useForm();
  useEffect(() => {
    let { userId } = JSON.parse(localStorage.getItem('userInfo'));
    console.log(userId);
    getUserInfo(userId).then(res => {
      setuserInfo(res.data.result);
      localStorage.setItem("avatar", res.data.result.avatar);
      localStorage.setItem("userInfo", JSON.stringify(res.data.result));
    })
  }, [])
  return (
    <>
      {contextHolder}
      <div className='mprofile-container'>
        <div className='mprofile'>
          <div className='avatar'>
            <Upload action={'https://api.codler.cn/api/dfs/upload'} onChange={onChange}>
              <Image className='useimgs' preview={false} src={userinfo.avatar} />
            </Upload>
          </div>
          <div className='nickname'>
            <div>昵称：{userinfo.nickname}</div>
            {/* <div><DesktopOutlined /> 入站4年</div> */}
          </div>
        </div>
        <div>
          <Button onClick={() => showModal()} block type='primary'>编辑资料</Button>
        </div>
      </div>
      <div className='profile-basic'>
        <Card title={'基础资料'}>
          <div className='account-item'>
            <div>用户昵称</div>
            <div>
              <Button type='link'>
                {userinfo.nickname}
              </Button>
            </div>
          </div>
          <Divider />
          <div className='account-item'>
            <div>用户Id</div>
            <div>
              <Button type='link'>
                {userinfo.userId}
              </Button>
            </div>
          </div>
          <Divider />
          <div className='account-item'>
            <div>用户性别</div>
            <div>
              <Button type='link'>
                {showGender(userinfo.gender)}
              </Button>
            </div>
          </div>
          <Divider />
          <div className='account-item'>
            <div>是否为管理员</div>
            <div>
              <Button type='link'>
                {userinfo.isAdmin ? '是' : '否'}
              </Button>
            </div>
          </div>
          <Divider />
          <div className='account-item'>
            <div>注册时间</div>
            <div>
              <Button type='link'>
                {dayjs(userinfo.createTime).format('YYYY年MM月DD日')}
              </Button>
            </div>
          </div>
          <Divider />
        </Card>
      </div>
      <Modal destroyOnClose title="编辑资料" cancelText='取消' okText='提交' open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
        footer={[]}>
        <Form
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          form={form}
        >
          <Form.Item
            label="昵称"
            name="nickname"
            rules={[
              {
                required: true,
                message: '请输入昵称!',
              },
            ]}
          >
            <Input placeholder='请输入昵称' defaultValue={userinfo.nickname} />
          </Form.Item>
          <Form.Item
            name="gender"
            label="性别"
            rules={[
              {
                required: true,
                message: '请选择性别!',
              },
            ]}
          >
            <Select placeholder="请选择性别" defaultValue={userinfo.gender} options={[
              { value: 1, label: '男' },
              { value: 2, label: '女' },
              { value: 0, label: '未知' },
            ]} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              提交
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
})

export default mprofile
