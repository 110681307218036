import request from '../request.js';
import { encrypt } from './rsa.js';
import cryptoJs from 'crypto-js';


// 接口授权
export const apiAuthorization = (headers, param) => {  
  const userInfo = localStorage.getItem('userInfo');
  
  const user = JSON.parse(userInfo);
  const token = localStorage.getItem('token');
  const userId = user?.userId;
  // 如果用户没有登录则不进行签名
  if (userId == undefined || userId == null || userId == 0 || token == undefined || token == null || token == '') {
    return headers;
  }
  const time = new Date();
  const timestamp = time.valueOf().toString();
  const minNum = 10000;
  const maxNum = 100;
  const nonce = parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10).toString();
  let conditions = bodyToConditions(param);
  const signature = (userId.toString() + timestamp + nonce + token + conditions).toLowerCase();
  headers['userid'] = userId;
  headers['timestamp'] = time.valueOf();
  headers['nonce'] = nonce;
  headers['signature'] = cryptoJs.MD5(signature).toString();
  
  return headers;
}

// api 通道数据签名
export const apiChannelAuthorization = async (headers, param) => {

  const getChannel = () => {
    return request({
      url: `/api/channel`,
      method: 'GET',
    })
  }
  return getChannel().then(res => {
    if (res.status == 200) {
      headers['channel'] = res.data.result.channel;
      headers['Content-Type'] = 'text/plan';
      return encrypt(bodyToConditions(param), res.data.result.key);
    }
    return '';
  }).catch(err => {
    console.log(err)
  });
}

// 将Body 内容转换为字符串
const bodyToConditions = (param) => {

  if (param === undefined || param === null || param === '')
    return '';

  if (typeof param === 'string')
    return param.toString();
  else if (typeof param === 'number')
    return param.toString();
  else if (typeof param === "bigint")
    return param.toString();
  else if (typeof param === 'boolean')
    return param.toString();
  return JSON.stringify(param);
}