import request from './request.js'

// 分享
export function shareWay(param) {
  return request({
    url: 'api/blog/share',
    method: 'POST',
    headers: {
      'api-type': 1
    },
    data: param
  })
}
// 重定向
export function redirectUrl(param) {
  return request({
    url: 'api/blog/redirectUrl/' + param.shortLink,
    method: 'GET',
    // headers: {
    //   'api-type': 1
    // },
    // params:param
  })
}
//获取详情
export function getDetailById(id) {
  const userInfo = localStorage.getItem("userInfo");
  const isLogin = !(userInfo == null || userInfo == undefined || userInfo == "");
  return request({
    url: 'api/blog/' + id,
    method: 'GET',
    headers: {
      'api-type': isLogin ? 1 : 0,
    },
  })
}
//点赞
export function getDz(param) {
  return request({
    url: 'api/blog/like',
    method: 'PUT',
    data: param,
    headers: {
      'api-type': 1,
    },
  })
}
//取消点赞
export function cancelDz(param) {
  return request({
    url: '/api/blog/dislike',
    method: 'PUT',
    data: param,
    headers: {
      'api-type': 1,
    },
  })
}
//获取评论列表
export function getPlList(id) {
  return request({
    url: '/api/comment/' + id,
    method: 'GET',
    headers: {
      'api-type': 1,
    },
  })
}
//用户评论
export function PlDetail(params) {
  return request({
    url: '/api/comment',
    method: 'POST',
    data: params,
    headers: {
      'api-type': 1,
    },
  })
}
//删除评论
export function DelPlDetail(params) {
  return request({
    url: '/api/comment/' + params,
    method: 'DELETE',
    headers: {
      'api-type': 1,
    },
  })
}