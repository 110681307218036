import axios from 'axios';
import { apiAuthorization, apiChannelAuthorization } from './utils/signature';
import app from "../app.config.json";

// create an axios instance
const service = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? app.axios.rel_baseURL : app.axios.dev_baseURL,
  timeout: app.axios.timeout
})

// request interceptor
service.interceptors.request.use(async config => {
  config.headers['Content-Type'] = 'application/json';

  // api-type : 1-接口授权签名；2-通常参数加密；3-通常参数加密+接口授权签名
  if (config.headers['api-type'] == 1)
    apiAuthorization(config.headers, config.data);
  if (config.headers['api-type'] == 2)
    config.data = await apiChannelAuthorization(config.headers, config.data);
  if (config.headers['api-type'] == 3) {
    config.data = await apiChannelAuthorization(config.headers, config.data);
    apiAuthorization(config.headers, config.data);
  }

  if (config.headers['Content-Type'] == '')
    config.headers['Content-Type'] = 'application/json';

  return config

}, error => {
  //请求拦截错误处理
  console.log('请求拦截器错误', error)

})

// response interceptor
service.interceptors.response.use(
  response => {
    if (response.status === 200) {
      return Promise.resolve(response);
    } else {
      console.error(response)
    }
  },
  error => {
    if (error.response) {
      // error.response包含了服务器响应的详细信息
      const statusCode = error.response.status;
      const errorMessage = error.response.data.message;

      // 根据不同的错误代码，显示不同的错误消息
      switch (statusCode) {
        case 400:
          return Promise.reject(`输入错误: ${errorMessage}`);
        case 403:
          window.location.href = '/login';
          localStorage.clear();
          return Promise.reject(`未授权: ${errorMessage}`);
        case 404:
          return Promise.reject(`页面不存在: ${errorMessage}`);
        case 500:
          return Promise.reject(`服务器错误，请稍后重试。`);
        default:
          return Promise.reject(`未知错误: ${errorMessage}`);
      }
    } else {
      // 其他错误（例如网络问题）
      return Promise.reject('网络错误，请检查你的连接。');
    }
  }
)



export default service
