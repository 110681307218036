import React from "react";
import { Navigate } from "react-router-dom";


import Home from '../views/home/home'
import Homes from '../views/homes/home'
import Check from '../views/homes/check/check'
import OverCheck from '../views/homes/overCheck/overCheck'
import Login from '../views/login/login'
import CodeLogin from '../views/login/cpn/cpn-login/code-login'
import EmailLogin from '../views/login/cpn/cpn-login/email-login'
import Clogin from '../views/login/cpn/cpn-login/c-login'
import Dustbin from '../views/homes/dustbin/dust'
import CodeRegister from '../views/login/cpn/cpn-register/code-register'
import EmailRegister from '../views/login/cpn/cpn-register/email-register'
import Cregister from '../views/login/cpn/cpn-register/c-register'
import Article from '../views/article/article'
import Profile from '../views/profile/profile'
import Details from '../views/details/details'
import Markown from '../views/markown/markown'
import Admin from '../views/homes/admin/admin'
import Disabled from '../views/homes/disabled/disabled';
import NotFound from "../views/state/404";


// const Home = React.lazy(() => import('../views/home/home'))
// const Homes = React.lazy(() => import('../views/homes/home'))
// const Check = React.lazy(() => import('../views/homes/check/check'))
// const OverCheck = React.lazy(() => import('../views/homes/overCheck/overCheck'))
// const Login = React.lazy(() => import('../views/login/login'))
// const CodeLogin = React.lazy(() => import('../views/login/cpn/cpn-login/code-login'))
// const EmailLogin = React.lazy(() => import('../views/login/cpn/cpn-login/email-login'))
// const Clogin = React.lazy(() => import('../views/login/cpn/cpn-login/c-login'))
// const Dustbin = React.lazy(()=> import('../views/homes/dustbin/dust'))

// const CodeRegister = React.lazy(() => import('../views/login/cpn/cpn-register/code-register'))
// const EmailRegister = React.lazy(() => import('../views/login/cpn/cpn-register/email-register'))
// const Cregister = React.lazy(() => import('../views/login/cpn/cpn-register/c-register'))

// const Article = React.lazy(() => import('../views/article/article'))
// const Profile = React.lazy(() => import('../views/profile/profile'))
// const Details = React.lazy(() => import('../views/details/details'))
// const Markown = React.lazy(() => import('../views/markown/markown'))

const routes = [
  {
    path: '/',
    element: <Navigate to='/home' />
  },
  {
    path: '/login',
    element: <Login />,
    children: [
      {
        path: "/login",
        element: <Navigate to="/login/code" />
      },
      {
        path: "/login/pwd",
        element: <Clogin />
      },
      {
        path: '/login/code',
        element: <CodeLogin />
      },
    ]
  },
  {
    path: '/home',
    element: <Home />,
    children: [
      {
        path: "/home",
        element: <Navigate to="/home/article" />
      },
      {
        path: "/home/article",
        element: <Article />
      },
      {
        path: "/home/profile",
        element: <Profile />
      },
      {
        path: "/home/homes",
        element: <Homes />,
        children: [
          {
            path: '/home/homes/check',
            element: <Check />
          },
          {
            path: '/home/homes/overcheck',
            element: <OverCheck />
          },
          {
            path: '/home/homes/dustbin',
            element: <Dustbin />
          },
          {
            path: '/home/homes/admin',
            element: <Admin />
          },
          {
            path: '/home/homes/disabled',
            element: <Disabled />
          }
        ]
      },

      {
        path: "/home/article/details",
        element: <Details />
      },
      {
        path: "/home/article/markown",
        element: <Markown />
      }
    ]
  },
  {
    path: '/404',
    element: <NotFound />
  }
]

export default routes