import { PROFILE } from "./constans"

//初始化数据
const initialState = {
  profile: {
    
  },
}
//定义reducer函数
function reducer(state = initialState, action) {
  switch (action.type) {
    case PROFILE:
      return {...state, profile:action.name}
    default:
      return state
  }
}

export default reducer