import React, { memo, useEffect, useState } from 'react'
import { Card } from 'antd';
import './css/login.css';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';


const tabTitle = [
  {
    key: 'login',
    label: '登 录',
  },
];
const content = {
  login: <Outlet />,
};
const login = memo((props) => {

  const navigate = useNavigate()
  const [activeTabKey, setactiveTabKey] = useState('login');
  const onTabChange = (key) => {
    setactiveTabKey(key);
    if (key === 'login') {
      navigate('/login/phone')
    }
    if (key === 'register') {
      navigate('/login/register')
    }
  };

  useEffect(() => {
    const userInfo = localStorage.getItem("userInfo");
    const isLogin = !(userInfo == null || userInfo == undefined || userInfo == "");
    if (isLogin) {
      navigate('/home/article');
    }
  })
  return (
    <div className='login'>
      {/* <div className='login-title'>欢迎登陆</div> */}
      <Card style={{ width: 500, height: 50, cursor: 'pointer', color: "#1677ff", fontSize: '20px', marginBottom: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
        <p>登录/注册</p>
      </Card>
      <Card
        style={{
          minWidth: '500px',
        }}
        tabList={tabTitle}
        activeTabKey={activeTabKey}
        onTabChange={onTabChange}
        tabProps={{
          size: 'large',
        }}
      >
        {content[activeTabKey]}
      </Card>
    </div>
  )
})

export default login

