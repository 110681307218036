import React, { memo, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, Avatar, Input, Dropdown, message, Layout, Menu, Row, Col } from 'antd'
import { useDispatch, useSelector } from 'react-redux';
import Logo from '../../assets/logo.png'
import './css/header.css'
import { UserOutlined, LogoutOutlined, createFromIconfontCN, MenuOutlined, SlidersFilled, HighlightOutlined, HomeOutlined } from '@ant-design/icons'
import { logOut } from '../../api/login';
import { userInfo } from '../../store/login/actionCreators';
const { Search } = Input;

const IconFont = createFromIconfontCN({
  scriptUrl: [
    '//at.alicdn.com/t/font_1788044_0dwu4guekcwr.js', // icon-javascript, icon-java, icon-shoppingcart (overridden)
    '//at.alicdn.com/t/font_1788592_a5xf2bdic3u.js', // icon-shoppingcart, icon-python
    '//at.alicdn.com/t/c/font_3859792_d6kiybrb8st.js'// icon-logo
  ],
});

const header = memo(() => {
  const [isLogin, setIsLogin] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()
  const onHome = (n) => {
    let params = {
      page: 1,
      pagesize: 10,
      state: null,
      category: n,
      searchKey: instxt
    }
    localStorage.removeItem('params')
    localStorage.setItem('params', JSON.stringify(params))

    setTabnum(n)
    navigate('/home/article')

  }
  const [instxt, setInstxt] = useState('')
  const [tabnum, setTabnum] = useState(1)
  const onSearch = function (e) {
    console.log(e)

    let params = {
      page: 1,
      pagesize: 10,
      state: null,
      category: tabnum,
      searchKey: e
    }
    localStorage.removeItem('params')
    localStorage.setItem('params', JSON.stringify(params))

    setInstxt(e)
    navigate('/home/article')
  }
  const items = [
    {
      key: 'profile',
      label: '个人中心',
      icon: <UserOutlined />,
    },
    {
      key: 'logout',
      label: '退出登录',
      icon: <LogoutOutlined />,

    },
  ];
  let items2 = [
    {
      label: '首页',
      key: 'home',
      icon: <HomeOutlined />,
      onClick: () => onHome(0)
    },
    {
      key: 'live',
      label: '生活',
      icon: <HighlightOutlined />,
      onClick: () => onHome(1)
    },
    {
      key: 'personage',
      label: '个人',
      icon: <UserOutlined />,
      onClick: () => onHome(2)
    },
    {
      key: 'system',
      label: '系统',
      icon: <SlidersFilled />,
      onClick: () => onHome(3)
    },
  ]
  const onClick = ({ key }) => {
    if (key == 'profile') {
      navigate('/home/profile')
    }
    if (key == 'logout') {
      logOut().then(res => {
        if (res.data.success) {
          localStorage.clear();
          message.success('退出成功');
        }
        localStorage.clear();
        navigate('/login');
      });
    }
  }
  if (location.pathname != '/home/article') {
    items2 = [];
  }
  const userStore = useSelector(state => state.userInfo);
  const [isuser, setIsuser] = useState(0)
  useEffect(() => {
    let userinfo = JSON.parse(localStorage.getItem('userInfo'));
    setIsLogin(userinfo != null);
    if (userinfo == null) {
      setIsuser(0)
    } else {
      setIsuser(1);
      if (userStore.userInfo.avatar == undefined || userStore.userInfo.avatar == null || userStore.userInfo.avatar == '') {
        userStore.userInfo.avatar = localStorage.getItem("avatar");
      }
    }
  })

  return (
    <Layout.Header >
      <Row>
        <Col xs={22} sm={20} md={6} lg={6}
          xl={5} xxl={4} style={{ "flexFlow": "nowrap", "height": "64px" }}>
          <h1 className={'logo'}>
            <a onClick={() => { navigate('/home/article') }}  >
              <IconFont type="icon-logo" />
              <span className={'logoText'}>微雨知</span>
            </a>
          </h1>
        </Col>
        <Col xs={0} sm={0} md={13} lg={16}
          xl={17} xxl={19}>
          <Menu mode="horizontal" onClick={onClick}
            defaultSelectedKeys={["home"]}
            style={{ borderBottom: "0", width: "auto" }}
            items={items2}
            theme="dark"
          />
        </Col>
        <Col xs={0} sm={0} md={3} lg={2} xl={2} xxl={1}>
          {
            isLogin ? (
              <Dropdown menu={{ items, onClick }} placement="bottom" arrow size='large'>
                <Avatar
                  className={isuser == 1 ? '' : 'hides'}
                  style={{ position: 'relative', top: '-2px', cursor: 'pointer' }}
                  src={userStore.userInfo.avatar} />
              </Dropdown>
            ) : (
              <button className={'headbotton'} onClick={() => { navigate('/login'); }}>
                登录/注册
              </button>
            )
          }
        </Col>
        <Col xs={2} sm={2} md={0} lg={0} xl={0} xxl={0}>
          {
            isLogin ? (
              <Dropdown menu={{ items, onClick }} placement="bottom" arrow size='large'>
                <Avatar className={isuser == 1 ? '' : 'hides'} style={{ position: 'relative', top: '-2px', cursor: 'pointer' }} src={userStore.userInfo.avatar} />
              </Dropdown>
            ) : (
              <Menu mode="horizontal" onClick={() => { navigate('/login'); }}
                style={{ borderBottom: "0", width: "auto" }}
                theme="light"
                overflowedIndicator={<MenuOutlined />}
                items={[
                  {
                    label: '登录/注册',
                    icon: <UserOutlined />,
                    onClick: () => { console.log("123123"); }
                  },
                ]} />
            )
          }
        </Col>
      </Row>
    </Layout.Header>
  )
})

export default header
