import React, { memo, useEffect } from 'react'
import { Tabs } from 'antd'
import MProfile from './cpn/mprofile';
import Account from './cpn/account';
import { useDispatch, useSelector } from 'react-redux';
import { getUserInfo } from '../../api/profile';
import store from '../../store';
import { profile as profileAction } from '../../store/profile/actionCreators'; 

const profile = memo(() => {
  const dispatch = useDispatch()
  const items = [
    {
      key: '1',
      label: '个人资料',
      children: <MProfile />,
    },
    {
      key: '2',
      label: '账号设置',
      children: <Account />,
    },
  ];
  const onChange = (key) => {

  };

  return (
    <div style={{ margin: '20px 0', padding: '20px 0' }}>
      <Tabs
        tabPosition={'left'}

        defaultActiveKey="1" items={items} onChange={onChange} />
    </div>
  )
})

export default profile
