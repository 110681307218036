import request from './request'
import app from "../app.config.json"

// 查询博客
export function queryblog(id) {
    return request({
        url: '/api/blog/' + id,
        method: 'GET',
        headers: {
            'api-type': 1
        },
        // data:params
    })
}

// 添加博客
export function addblog(params) {
    return request({
        url: '/api/blog',
        method: 'POST',
        headers: {
            'api-type': 1
        },
        data: params
    })
}
// 编辑博客
export function editblog(params) {
    return request({
        url: '/api/blog',
        method: 'PUT',
        headers: {
            'api-type': 1,
        },
        data: params
    })
}

// 发布博客
export function publishBlog(params) {
    return request({
        url: '/api/blog/publish',
        method: 'PUT',
        headers: {
            'api-type': 1,
        },
        data: params
    })
}

// 撤销发布博客
export function unpublishBlog(params) {
    return request({
        url: '/api/blog/unPublish',
        method: 'PUT',
        headers: {
            'api-type': 1,
        },
        data: params
    })
}

// 上传图片
export function uploadImg(params) {
    const baseURL = process.env.NODE_ENV === 'production' ? app.axios.rel_baseURL : app.axios.dev_baseURL;
    return fetch(baseURL + 'api/dfs/upload', {
        method: "POST",
        body: params,
    });
}