import request from './request.js'

// 登录
export function login(param) {
  return request({
    url: '/api/user/login',
    method: 'POST',
    data: param,
    headers: {
      "api-type": 2,
    }
  })
}

// 发送验证码
export function sendCaptcha(param) {
  return request({
    url: `/api/message/sendCaptcha`,
    method: 'POST',
    data: param,
    headers: {
      "api-type": 2,
    }
  })
}
//忘记密码
export function forgetPwd(param) {
  return request({
    url: `/api/user/pwd/forgot`,
    method: 'PUT',
    data: param,
    headers: {
      "api-type": 2,
    }
  })
}

// 退出登录
export function logOut(){
  return request({
    url: '/api/user/logout',
    method: 'POST',
    headers: {
      "api-type": 1,
    }
  })
}
