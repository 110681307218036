import React, { memo, useState, useEffect } from 'react'
import { Space, Table, Tag, Button, message, Modal, Form } from 'antd';
import { getAdminList } from '../../../api/home'
import { startuse } from '../../../api/startblog';
import { forbiddens } from '../../../api/forbidden';
import { useNavigate } from "react-router-dom";
import TextArea from 'antd/es/input/TextArea';
import { Content } from 'antd/es/layout/layout';
const check = memo(() => {
    const [current, setCurrent] = useState(1);
    const [pageSize, setPageSize] = useState(8);
    const [dataList, setDatalist] = useState([]);//列表数据
    const [isOpen, setIsOpen] = useState(false);
    const [indexBlog, setIndexBlog] = useState({});
    const [text, setText] = useState('');
    const [form] = Form.useForm();
    const navigate = useNavigate()

    // 请求发布箱
    const getdrafs = () => {
        let parmas = {
            category: 0,
            page: current,
            pagesize: pageSize,
            searchKey: '',
        }
        getAdminList(parmas).then(res => {
            let data = res.data.result.data.map((item, i) => {
                return {
                    key: i,
                    name: item.author,
                    content: item.content,
                    title: item.title,
                    id: item.id,
                    mainImage: item.mainImage,
                    state: item.state,
                    reason: item.reason,
                }
            });
            setDatalist(data)
        })
    }

    //启用博客
    const startblog = (index) => {
        startuse(dataList[index].id).then(res => {
            if (res.data.success == true) {
                message.success('启用成功');
                getdrafs()
            } else {
                message.error(res.response.data.errorMessage);
            }
        }
        )
    }
    //禁用博客
    const forbiddenblog = () => {
        if (text == '' || text == null)
            message.error('请输入禁用原因');
        forbiddens({
            id: dataList[indexBlog].id,
            reason: text,
        }).then(res => {
            if (res.data.success == true) {
                message.success('禁用成功');
                getdrafs()
            } else {
                message.error(res.response.data.errorMessage);
            }
            setIsOpen(false);
            setText('');
        });
    }

    useEffect(() => {
        getdrafs()
    }, [])

    const columns = [
        {
            title: '作者',
            dataIndex: 'name',
            align: 'center',
            key: 'name',
            render: (text) => <a>{text}</a>,
        },
        {
            title: '简要内容',
            dataIndex: 'content',
            key: 'content',
            align: 'center',
        },
        {
            title: '标题',
            key: 'title',
            dataIndex: 'title',
            align: 'center',
        },
        {
            title: '原因',
            key: 'reason',
            dataIndex: 'reason',
            align: 'center',
        },
        {
            title: '操作',
            key: 'action',
            dataIndex: 'action',
            align: 'center',
            render: (text, record, index) => {
                if (record.state == 0) {
                    return (
                        <Space>
                            <Button onClick={() => navigate('/home/article/details?id=' + record.id)} type='default'>查看</Button>
                            <Button onClick={() => startblog(index)} type="primary">启用</Button>
                        </Space>
                    )
                } else if (record.state == 2) {
                    return (
                        <Space>
                            <Button onClick={() => navigate('/home/article/details?id=' + record.id)} type="default">查看</Button>
                            <Button danger onClick={() => {
                                setIndexBlog(index);
                                setIsOpen(true);
                            }} type="primary">禁用</Button>
                        </Space>
                    )
                } else if (record.state == 4) {
                    return (
                        <Space>
                            <Button onClick={() => navigate('/home/article/details?id=' + record.id)} type="default">查看</Button>
                            <Button onClick={() => startblog(index)} type="primary">启用</Button>
                            <Button danger onClick={() => {
                                setIndexBlog(index);
                                setIsOpen(true);
                            }} type="primary">禁用</Button>
                        </Space>
                    )
                }
                return (<></>);
            },
        },
    ];

    return (<>
        <Content>
            <Table
                bordered
                columns={columns}
                dataSource={dataList}
                pagination={{
                    current,
                    pageSize,
                    total: dataList.length,
                    showTotal(totla, range) {
                        return `共 ${totla} 条`
                    },
                    onChange(page, pageSize) {
                        setCurrent(page)
                        setPageSize(pageSize)
                    }
                }} />
        </Content>
        <Modal title="禁用博客" open={isOpen} onCancel={() => { setIsOpen(false); form.resetFields(); }} centered footer={[
            <Button key="back" onClick={() => { setIsOpen(false); form.resetFields(); }}>取消</Button>,
            <Button key="back1" type='primary' danger onClick={() => { forbiddenblog() }}>确定</Button>,
        ]}>
            <Form name="basic" form={form}>
                <Form.Item label="禁用原因" name='text'>
                    <TextArea
                        onChange={(e) => setText(e.target.value)}
                        placeholder="请输入禁用原因"
                        autoSize={{
                            minRows: 3,
                            maxRows: 5,
                        }}
                    />
                </Form.Item>
            </Form>
        </Modal>
    </>
    )
})

export default check
