import store from '../store';
import { profile } from '../store/profile/actionCreators';
import request from './request'
import axios from 'axios';
import cryptoJs from 'crypto-js';
import { Button, message } from 'antd';

export function getUserInfo(userid) {
  return request({
    url: `/api/user/${userid}`,
    method: 'get',
    headers: {
      'api-type': 1
    },
    data: ""
  });
}

//更新用户信息
export function updateUserInfo(param) {
  return request({
    url: `/api/user`,
    method: 'put',
    headers: {
      'api-type': 1
    },
    data: param,
  });
}

