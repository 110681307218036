import { USERINFO } from "./constans"

//初始化数据
const initialState = {
  userInfo: {name:'hanjie'},
}
//定义reducer函数
function reducer(state = initialState, action) {
  switch (action.type) {
    case USERINFO:
      return {...state, userInfo:action.name}
    default:
      return state
  }
}

export default reducer