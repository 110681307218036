import request from './request.js'
// 请求列表
export function getList(param) {
    return request({
      url:'api/blog/list',
    //   url: '/api/blog/list?current_page='+param.current_page+'&page_size='+param.page_size+'&state='+param.state+'&category='+param.category+'&search_key='+param.search_key,
      method: 'GET',
      params:param,
      // headers: {
      //   'api-type': 1,
      // },
    })
  }