import request from './request'

//删除博客
export function delete_(params) {
    return request({
        url: '/api/blog',
        method: 'DELETE',
        data: {
            id: params.id,
            state: params.state
        },
        headers: {
            'api-type': 1,
        },
    })
}
// 恢复博客
export function recover_(id) {
    return request({
        url: '/api/blog/restore',
        method: 'PUT',
        data: {
            id: id
        },
        headers: {
            'api-type': 1,
        },
    })
}