import React, { memo, useEffect } from 'react'
import Header from '../header/header'
import { Outlet, useLocation } from 'react-router-dom'
import { Footer } from 'antd/es/layout/layout'
import { useSelector } from 'react-redux'
import { getUserInfo } from '../../api/profile'
import { Layout } from 'antd'

const home = memo((props) => {
  const location = useLocation()

  const userInfo = useSelector(state => state.user)
  useEffect(() => {
    // getUserInfo(userInfo.user.userId)
  })

  return (
    <div>
      <Layout.Header>
        <div>
          <div style={{ width: '1200px', margin: '0 auto' }}>
            <Header />
          </div>
        </div>
      </Layout.Header>
      <Layout.Content>
        <div style={{ width: '1200px', margin: '0 auto' }}>
          <Outlet />
        </div>
      </Layout.Content>

      {/* {
        location.pathname === '/home/article' ? '' : <Footer style={{ textAlign: 'center',height:'10vh'}}>
          BLOG ©{new Date().getFullYear()} Created by myself
        </Footer>
      } */}

    </div>

  )
})

export default home

