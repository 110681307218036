import request from './request.js'
let userid=''
if(localStorage.getItem('userInfo')==null){
    userid=''
}else{
    userid=JSON.parse(localStorage.getItem('userInfo')).userId
}
// 开启博客
export function startuse(param){
    return request({
        url:`/api/blog/enable`,
        method:'PUT',
        data:param,
        headers: {
            'api-type': 1,
            'userid':userid,
        },
    })
}