import { USERINFO } from "./constans"

//初始化数据
const initialState = {
  user: {
    userId: -1,
    token: "",
    name: "",
    phone: "",
    email: "",
    isAdmin: false
  },
}
//定义reducer函数
function reducer(state = initialState, action) {
  switch (action.type) {
    case USERINFO:
      return { ...state, user: action.name }
    default:
      return state
  }
}

export default reducer