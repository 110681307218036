import React, { memo, useRef, useState, useEffect } from 'react'
import Editor from 'for-editor';
import './markown.css';
import { Form, Input, message, Modal, Radio, Upload, Button, Image, Space } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { addblog, editblog, uploadImg } from '../../api/markdown';
import { useLocation, useNavigate } from "react-router-dom";
import { queryblog } from '../../api/markdown'

export default memo(() => {
  const [contentvalue, setcontentvalue] = useState('');
  const [titlevalue, setTitlevalue] = useState('');
  const [category, setCategory] = useState(1);
  const [MainImage, setmainImage] = useState('');
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const formRef = React.createRef();
  const [state, setState] = useState(-1);
  const [id, setId] = useState(0);
  const navigator = useNavigate();

  useEffect(() => {
    let isedit = location.state.isedit
    if (isedit == 1) {
      queryblog_();
    }
  }, []);

  // 取消
  const handleCancel = () => {
    setIsModalOpen(false);
  }

  // 保存
  const handleSave = () => {
    setIsModalOpen(true);
  }

  // 查询博客内容
  const queryblog_ = () => {

    let parmas = {
      id: location.state.info.id
    }
    queryblog(location.state.info.id).then(res => {
      if (res.data.success) {
        console.log(res.data);
        setcontentvalue(res.data.result.content);
        setTitlevalue(res.data.result.title);
        setmainImage(res.data.result.mainImage);
        setState(res.data.result.state);
        setCategory(res.data.result.category);
        setId(res.data.result.id);
      } else {
        message.error(res.data.errorMessage);
        navigator('/home/article');
      }
    })
  }

  // 添加博客内容
  const handleChange = (value) => {
    console.log(value)
    setcontentvalue(value)
  }

  // 图片上传
  const props = {
    name: 'file',
    action: 'https://api.codler.cn/api/dfs/upload',
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
        let index = info.fileList.length - 1;
        setmainImage(info.fileList[index].response.result)
      }
    },
  };

  // 发布
  const clickpublish = (value) => {
    if (contentvalue == '') {
      messageApi.error('请输入博客内容');
      return;
    }

    formRef.current.validateFields().then((values) => {
      let params = {
        Title: values.title,
        Content: contentvalue,
        MainImage: MainImage,
        Category: values.category == undefined ? 1 : values.category,
        State: parseInt(value)
      }
      if (id > 0)
        params.id = id;
      let isedit = location.state.isedit;
      if (isedit == 1) {
        editblog(params).then(res => {
          if (res.data.success) {
            messageApi.info('操作成功');
            setIsModalOpen(false);
          }
          else {
            messageApi.error(res.data.errorMessage);
          }
        })
      } else {
        addblog(params).then(res => {
          if (res.data.success) {
            messageApi.info('操作成功');
            setIsModalOpen(false);
          } else {
            messageApi.error(res.data.errorMessage);
          }
        })
      }
    }).catch((info) => {
      console.log('Validate Failed:', info);
    })
  }

  const editorRef = useRef();

  // 错误提示
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  }

  const addImg = ($file) => {
    console.log(editorRef.current);
    console.log($file);
    const formData = new FormData();
    formData.append("file", $file);
    uploadImg(formData).then(res => {
      console.log(res);
      return res.json();
    }).then(res => {
      if (res.success) {
        editorRef.current.$img2Url($file.name, res.result);
      } else {
        messageApi.error(res.errorMessage);
      }
    }).catch(err => {
      messageApi.error(err);
    })
    // var fileReader = new FileReader()
    // fileReader.readAsDataURL($file);
    // fileReader.onload = function () {
    //   console.log(fileReader);
    //   // uploadImg(fileReader.result).then(res => {
    //   //   if (res.data.success) {
    //   //     editorRef.current.$img2Url($file.name, res.data.result);
    //   //   } else {
    //   //     messageApi.error(res.data.errorMessage);
    //   //   }
    //   // }).catch(err => {
    //   //   messageApi.error(err);
    //   // })
    // }

  }
  const [messageApi, contextHolder] = message.useMessage();

  return (
    <>
      <div style={{ margin: '20px 0' }}>
        {contextHolder}
        <Editor
          ref={editorRef}
          subfield={true}
          preview={true}
          addImg={($file) => addImg($file)}
          value={contentvalue}
          onSave={() => handleSave()}
          onChange={(value) => handleChange(value)}

        />
      </div>
      <Modal title="保存博客" open={isModalOpen} onCancel={handleCancel} footer={[
        state == 0 ? (
          <Button type="primary" htmlType="submit" onClick={() => clickpublish(4)}>提交审核</Button>
        ) : (
          <Space>
            <Button type="primary" htmlType="submit" onClick={() => clickpublish(2)}>发布</Button>
            <Button type="default" htmlType="submit" onClick={() => clickpublish(1)}>保存</Button>
          </Space>
        )
      ]}>
        <Form
          ref={formRef}
          name="basic"
          onFinishFailed={onFinishFailed}
          autoComplete="off">
          <Form.Item label="标题" name='title' required rules={[
            {
              required: true,
              message: '请输入标题',
            }
          ]} initialValue={titlevalue}>
            <Input placeholder='请输入标题' defaultValue={titlevalue} />
          </Form.Item>
          <Form.Item label="分类" name='category' initialValue={category}>
            <Radio.Group defaultValue={category}>
              <Radio value={1}>生活</Radio>
              <Radio value={2}>个人</Radio>
              <Radio value={3}>系统</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="封面" name='mainImage' rules={[
            {
              required: true,
              message: '请上传封面',
            }
          ]} initialValue={MainImage}>
            <Upload {...props}>
              {
                !MainImage ? <Button icon={<UploadOutlined />}>Upload</Button> : (
                  <Image src={MainImage} preview={false} />
                )
              }
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
})
