import store from '../store';
import cryptoJs from 'crypto-js';
import request from './request.js';

// 修改密码
export function updatePwdInfo(param) {
  return request({
    url: '/api/user/pwd',
    method: 'PUT',
    data: param,
    headers: {
      "api-type": 3,
    }
  });
}
// 修改手机号
export function updatePhoneInfo(param) {
  return request({
    url: '/api/user/phone',
    method: 'PUT',
    data: param,
    headers: {
      "api-type": 1,
    }
  });
}
//修改邮箱
export function updateEmailInfo(param) {
  return request({
    url: '/api/user/email',
    method: 'PUT',
    data: param,
    headers: {
      "api-type": 1,
    }
  });
}